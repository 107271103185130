/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AgentDetail } from '../models/agent-detail';
import { CompanyDetail } from '../models/company-detail';
import { createCompanyPermission } from '../fn/user/create-company-permission';
import { CreateCompanyPermission$Params } from '../fn/user/create-company-permission';
import { deleteCompanyProductPermission } from '../fn/user/delete-company-product-permission';
import { DeleteCompanyProductPermission$Params } from '../fn/user/delete-company-product-permission';
import { exportEventAudit } from '../fn/user/export-event-audit';
import { ExportEventAudit$Params } from '../fn/user/export-event-audit';
import { getAgent } from '../fn/user/get-agent';
import { GetAgent$Params } from '../fn/user/get-agent';
import { getAgentList } from '../fn/user/get-agent-list';
import { GetAgentList$Params } from '../fn/user/get-agent-list';
import { GetAgentPermissionsResponse } from '../models/get-agent-permissions-response';
import { GetAgentsResponse } from '../models/get-agents-response';
import { GetCompaniesResponse } from '../models/get-companies-response';
import { getCompany } from '../fn/user/get-company';
import { GetCompany$Params } from '../fn/user/get-company';
import { getCompanyList } from '../fn/user/get-company-list';
import { GetCompanyList$Params } from '../fn/user/get-company-list';
import { getCompanyProductPermissions } from '../fn/user/get-company-product-permissions';
import { GetCompanyProductPermissions$Params } from '../fn/user/get-company-product-permissions';
import { getContentReport } from '../fn/user/get-content-report';
import { GetContentReport$Params } from '../fn/user/get-content-report';
import { GetEventAuditEvidenceListResponse } from '../models/get-event-audit-evidence-list-response';
import { getEventAuditList } from '../fn/user/get-event-audit-list';
import { GetEventAuditList$Params } from '../fn/user/get-event-audit-list';
import { GetReportContentResponse } from '../models/get-report-content-response';
import { resetAgentPassword } from '../fn/user/reset-agent-password';
import { ResetAgentPassword$Params } from '../fn/user/reset-agent-password';
import { Transporter } from '../models/transporter';


/**
 * Služby pre používateľov
 */
@Injectable({ providedIn: 'root' })
export class UserService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getCompanyList()` */
  static readonly GetCompanyListPath = '/admin/companies';

  /**
   * Načítanie zoznamu spoločností.
   *
   * Služba na načítanie zoznamu spoločností. Podporuje filtrovanie, stránkovanie, zoradenie
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyList$Response(params?: GetCompanyList$Params, context?: HttpContext): Observable<StrictHttpResponse<GetCompaniesResponse>> {
    return getCompanyList(this.http, this.rootUrl, params, context);
  }

  /**
   * Načítanie zoznamu spoločností.
   *
   * Služba na načítanie zoznamu spoločností. Podporuje filtrovanie, stránkovanie, zoradenie
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyList(params?: GetCompanyList$Params, context?: HttpContext): Observable<GetCompaniesResponse> {
    return this.getCompanyList$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetCompaniesResponse>): GetCompaniesResponse => r.body)
    );
  }

  /** Path part for operation `getCompany()` */
  static readonly GetCompanyPath = '/admin/companies/{id}';

  /**
   * Načítanie detailu spoločnosti.
   *
   * Služba načíta detail spoločnosti podľa id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompany$Response(params: GetCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyDetail>> {
    return getCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * Načítanie detailu spoločnosti.
   *
   * Služba načíta detail spoločnosti podľa id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompany(params: GetCompany$Params, context?: HttpContext): Observable<CompanyDetail> {
    return this.getCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyDetail>): CompanyDetail => r.body)
    );
  }

  /** Path part for operation `getCompanyProductPermissions()` */
  static readonly GetCompanyProductPermissionsPath = '/admin/companies/{id}/product';

  /**
   * Načítanie produktových oprávnení pre spoločnosť.
   *
   * Služba načíta zoznamu produktov, na ktoré má spoločnosť oprávnenie
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyProductPermissions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyProductPermissions$Response(params: GetCompanyProductPermissions$Params, context?: HttpContext): Observable<StrictHttpResponse<GetAgentPermissionsResponse>> {
    return getCompanyProductPermissions(this.http, this.rootUrl, params, context);
  }

  /**
   * Načítanie produktových oprávnení pre spoločnosť.
   *
   * Služba načíta zoznamu produktov, na ktoré má spoločnosť oprávnenie
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyProductPermissions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyProductPermissions(params: GetCompanyProductPermissions$Params, context?: HttpContext): Observable<GetAgentPermissionsResponse> {
    return this.getCompanyProductPermissions$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetAgentPermissionsResponse>): GetAgentPermissionsResponse => r.body)
    );
  }

  /** Path part for operation `createCompanyPermission()` */
  static readonly CreateCompanyPermissionPath = '/admin/companies/{id}/product';

  /**
   * Pridanie oprávnenia na produkt.
   *
   * Služba pridá oprávnenie na zvolený produkt spoločnosti.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCompanyPermission()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCompanyPermission$Response(params: CreateCompanyPermission$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return createCompanyPermission(this.http, this.rootUrl, params, context);
  }

  /**
   * Pridanie oprávnenia na produkt.
   *
   * Služba pridá oprávnenie na zvolený produkt spoločnosti.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCompanyPermission$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCompanyPermission(params: CreateCompanyPermission$Params, context?: HttpContext): Observable<boolean> {
    return this.createCompanyPermission$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `deleteCompanyProductPermission()` */
  static readonly DeleteCompanyProductPermissionPath = '/admin/companies/{id}/product/{permissionId}';

  /**
   * Zmazanie oprávnenia.
   *
   * Služba slúži na zmazanie oprávnenia na produkt pre spoločnosť podľa id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCompanyProductPermission()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCompanyProductPermission$Response(params: DeleteCompanyProductPermission$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return deleteCompanyProductPermission(this.http, this.rootUrl, params, context);
  }

  /**
   * Zmazanie oprávnenia.
   *
   * Služba slúži na zmazanie oprávnenia na produkt pre spoločnosť podľa id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCompanyProductPermission$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCompanyProductPermission(params: DeleteCompanyProductPermission$Params, context?: HttpContext): Observable<boolean> {
    return this.deleteCompanyProductPermission$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `getAgentList()` */
  static readonly GetAgentListPath = '/admin/agents';

  /**
   * Načítanie zoznamu agentov.
   *
   * Služba načíta zoznam agentov. Podporuje filtrovanie, stránkovanie, zoradenie
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAgentList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgentList$Response(params?: GetAgentList$Params, context?: HttpContext): Observable<StrictHttpResponse<GetAgentsResponse>> {
    return getAgentList(this.http, this.rootUrl, params, context);
  }

  /**
   * Načítanie zoznamu agentov.
   *
   * Služba načíta zoznam agentov. Podporuje filtrovanie, stránkovanie, zoradenie
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAgentList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgentList(params?: GetAgentList$Params, context?: HttpContext): Observable<GetAgentsResponse> {
    return this.getAgentList$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetAgentsResponse>): GetAgentsResponse => r.body)
    );
  }

  /** Path part for operation `getAgent()` */
  static readonly GetAgentPath = '/admin/agents/{id}';

  /**
   * Načítanie detailu agenta.
   *
   * Služba načíta detail agenta podľa id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAgent()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgent$Response(params: GetAgent$Params, context?: HttpContext): Observable<StrictHttpResponse<AgentDetail>> {
    return getAgent(this.http, this.rootUrl, params, context);
  }

  /**
   * Načítanie detailu agenta.
   *
   * Služba načíta detail agenta podľa id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAgent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgent(params: GetAgent$Params, context?: HttpContext): Observable<AgentDetail> {
    return this.getAgent$Response(params, context).pipe(
      map((r: StrictHttpResponse<AgentDetail>): AgentDetail => r.body)
    );
  }

  /** Path part for operation `resetAgentPassword()` */
  static readonly ResetAgentPasswordPath = '/admin/agent/{id}/resetPassword';

  /**
   * Resetovanie hesla pre agentov.
   *
   * Resetovanie hesla pre agentov
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resetAgentPassword()` instead.
   *
   * This method doesn't expect any request body.
   */
  resetAgentPassword$Response(params: ResetAgentPassword$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return resetAgentPassword(this.http, this.rootUrl, params, context);
  }

  /**
   * Resetovanie hesla pre agentov.
   *
   * Resetovanie hesla pre agentov
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resetAgentPassword$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resetAgentPassword(params: ResetAgentPassword$Params, context?: HttpContext): Observable<boolean> {
    return this.resetAgentPassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `getEventAuditList()` */
  static readonly GetEventAuditListPath = '/admin/eventAuditEvidence';

  /**
   * Načítanie zoznamu udalostí.
   *
   * Služba načíta zoznam udalostí. Podporuje filtrovanie, stránkovanie, zoradenie
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEventAuditList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEventAuditList$Response(params?: GetEventAuditList$Params, context?: HttpContext): Observable<StrictHttpResponse<GetEventAuditEvidenceListResponse>> {
    return getEventAuditList(this.http, this.rootUrl, params, context);
  }

  /**
   * Načítanie zoznamu udalostí.
   *
   * Služba načíta zoznam udalostí. Podporuje filtrovanie, stránkovanie, zoradenie
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEventAuditList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEventAuditList(params?: GetEventAuditList$Params, context?: HttpContext): Observable<GetEventAuditEvidenceListResponse> {
    return this.getEventAuditList$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetEventAuditEvidenceListResponse>): GetEventAuditEvidenceListResponse => r.body)
    );
  }

  /** Path part for operation `getContentReport()` */
  static readonly GetContentReportPath = '/admin/eventAuditEvidence/report';

  /**
   * Načítanie zoznamu pre report obsahu.
   *
   * Služba načíta zoznam pre report obsahu. Podporuje filtrovanie, stránkovanie, zoradenie
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getContentReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContentReport$Response(params: GetContentReport$Params, context?: HttpContext): Observable<StrictHttpResponse<GetReportContentResponse>> {
    return getContentReport(this.http, this.rootUrl, params, context);
  }

  /**
   * Načítanie zoznamu pre report obsahu.
   *
   * Služba načíta zoznam pre report obsahu. Podporuje filtrovanie, stránkovanie, zoradenie
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getContentReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContentReport(params: GetContentReport$Params, context?: HttpContext): Observable<GetReportContentResponse> {
    return this.getContentReport$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetReportContentResponse>): GetReportContentResponse => r.body)
    );
  }

  /** Path part for operation `exportEventAudit()` */
  static readonly ExportEventAuditPath = '/admin/eventAuditEvidence/export';

  /**
   * Export udalostí.
   *
   * Služba vráti export udalostí vo formáte excel súboru
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportEventAudit()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportEventAudit$Response(params?: ExportEventAudit$Params, context?: HttpContext): Observable<StrictHttpResponse<Transporter>> {
    return exportEventAudit(this.http, this.rootUrl, params, context);
  }

  /**
   * Export udalostí.
   *
   * Služba vráti export udalostí vo formáte excel súboru
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exportEventAudit$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportEventAudit(params?: ExportEventAudit$Params, context?: HttpContext): Observable<Transporter> {
    return this.exportEventAudit$Response(params, context).pipe(
      map((r: StrictHttpResponse<Transporter>): Transporter => r.body)
    );
  }

}
