/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ContentEventTypeEnum } from '../../models/content-event-type-enum';
import { GetReportContentResponse } from '../../models/get-report-content-response';

export interface GetContentReport$Params {
  eventType: ContentEventTypeEnum;

/**
 * dátum udalosti od
 */
  eventDateFrom?: string;

/**
 * dátum udalosti do
 */
  eventDateTo?: string;

/**
 * názov spoločností
 */
  companies?: Array<string>;

/**
 * názov obsahu
 */
  content?: string;

/**
 * Číslo stránky
 */
  page?: number;

/**
 * Počet záznamov na stránku
 */
  size?: number;

/**
 * Zoznam atribútov pre zoraďovanie v tvare **column,column**
 */
  sort?: string;

/**
 * Smer zoraďovania atribútov
 */
  direction?: 'ASC' | 'DESC';
}

export function getContentReport(http: HttpClient, rootUrl: string, params: GetContentReport$Params, context?: HttpContext): Observable<StrictHttpResponse<GetReportContentResponse>> {
  const rb = new RequestBuilder(rootUrl, getContentReport.PATH, 'get');
  if (params) {
    rb.query('eventType', params.eventType, {});
    rb.query('eventDateFrom', params.eventDateFrom, {});
    rb.query('eventDateTo', params.eventDateTo, {});
    rb.query('companies', params.companies, {});
    rb.query('content', params.content, {});
    rb.query('page', params.page, {});
    rb.query('size', params.size, {});
    rb.query('sort', params.sort, {});
    rb.query('direction', params.direction, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<GetReportContentResponse>;
    })
  );
}

getContentReport.PATH = '/admin/eventAuditEvidence/report';
