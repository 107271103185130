/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ContentEventTypeEnum } from '../../models/content-event-type-enum';
import { Transporter } from '../../models/transporter';

export interface ExportEventAudit$Params {

/**
 * dátum udalosti od
 */
  eventDateFrom?: string;

/**
 * dátum udalosti do
 */
  eventDateTo?: string;

/**
 * typ udalosti
 */
  eventType?: ContentEventTypeEnum;

/**
 * názov obsahu
 */
  content?: string;

/**
 * email používateľa
 */
  userEmail?: string;

/**
 * názov spoločností
 */
  companies?: Array<string>;

/**
 * rola
 */
  role?: string;

/**
 * typ reportu - zadkladny/specificky
 */
  baseReport?: boolean;
}

export function exportEventAudit(http: HttpClient, rootUrl: string, params?: ExportEventAudit$Params, context?: HttpContext): Observable<StrictHttpResponse<Transporter>> {
  const rb = new RequestBuilder(rootUrl, exportEventAudit.PATH, 'get');
  if (params) {
    rb.query('eventDateFrom', params.eventDateFrom, {});
    rb.query('eventDateTo', params.eventDateTo, {});
    rb.query('eventType', params.eventType, {});
    rb.query('content', params.content, {});
    rb.query('userEmail', params.userEmail, {});
    rb.query('companies', params.companies, {});
    rb.query('role', params.role, {});
    rb.query('baseReport', params.baseReport, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Transporter>;
    })
  );
}

exportEventAudit.PATH = '/admin/eventAuditEvidence/export';
